import React, { Component } from 'react';
import {NavLink} from "react-router-dom";
import ScrollAnimation from 'react-animate-on-scroll';
import Greeting from "../partials/Greeting";
import axios from "axios";
import {API_BASE} from "../../config/env";


class EntertainmentList extends Component{


    constructor(props) {
        super(props);
        this.state = {
            main: [],
            articles: []
        }

    }


    componentWillMount() {

        let lang = localStorage.getItem('language');
        axios.get(`${API_BASE}/entertainmentArticles/menu/${this.props.location.state.keyword.keyword}/lang/${lang}`)
            .then(result => result.data)
            .then(data => {
                this.setState(prevState => ({
                    articles:  data
                }))
            })
            .catch(error => console.log(error));

    }

    render(){

        // let subMenuItems = [
        //     { label : 'Milli Azərbaycan Tarix Muzeyi', name: 'national' , link: '/national'},
        //     { label : 'Hacı Zeynalabdin Tağıyevin ev muzeyi', name: 'taghiyev', link: '/taghiyev'},
        //     { label : 'İstiqlal Muzeyi', name: 'istiglal' , link: '/istiglal'},
        //     { label : 'Xalça Muzeyi', name: 'xalcha' , link: '/xalcha'}
        //
        // ];

        let entertainmentList = this.state.articles.map((link,index) =>{

            return(
                <ScrollAnimation animateIn='fadeIn' animateOut="fadeOut" className={"menu__animated" + index}
                                 scrollableParentSelector='#scrolly-div-animatePreScroll' duration={1}
                                 key={index}>
                    <div className="menu__item-w--inner">
                        <NavLink to={{ pathname:`/main/${this.props.location.state.main.keyword}/${link.id}`,state: { keyword: link} }} className='menu__item menu__item--inner'>
                            <span className="menu__item--icon menu__item--inner-icon"></span>
                            <span className="menu__txt menu__txt--inner">{link.name}</span>
                        </NavLink>
                    </div>
                </ScrollAnimation>
            )
        });

        // let title = 'Entertainment';

        return(
            <div>
                <Greeting title={this.props.location.state.main.name}/>
                <div className="menu menu--inner menu__bar" id={"scrolly-div-animatePreScroll"}>
                    <div className="menu__line"></div>
                    <div className="menu__item-w active this__menu">
                        <NavLink to='/' className='menu__item'>
                            <span className={"menu__item--icon menu__item--icon-entertainment"}></span>
                            {/*<span className="menu__txt">{this.props.main.label}</span>*/}
                        </NavLink>
                        <div className="menu__inner-page">
                            <span className="menu__item--icon menu__item--inner-icon"></span>
                            <span className="menu__txt menu__txt--inner">{this.props.location.state.keyword.name}</span>
                        </div>
                    </div>
                    {entertainmentList}
                </div>
            </div>
        );
    }

}

export default EntertainmentList;