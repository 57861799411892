import React , { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import globalTranslations from "../translations/global.json";
import Home from './Home';
import {Route, Switch} from "react-router-dom";
import Organizations from "./pages/Organizations";
import Entertainment from "./pages/Entertainment";
// import Restaurants from "./pages/Restaurants";
import EntertainmentList from "./pages/EntertainmentList";
import InnerPage from "./pages/InnerPage";
import AppBar from './map/app-bar'
import Search from './Search';



class Main extends Component{



    constructor(props) {
        super(props);

        this.props.initialize({
            languages: [
                { name: "Azerbaijani", code: "az" },
                { name: "English", code: "en" },
                // { name: "Russian", code: "ru" }
            ],
            translation: globalTranslations,
            options: { renderToStaticMarkup }
        });
    }


    render(){

        return(
            <div className="app">
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route exact path='/organization' component={Organizations}/>
                    <Route exact path='/entertainment' component={Entertainment}/>
                    <Route exact path='/restaurant' component={Organizations}/>
                    <Route exact path='/alo141' component={Organizations}/>
                    <Route exact path='/search' component={Search}/>
                    <Route exact path='/entertainment/:id' component={EntertainmentList}/>
                    <Route exact path='/main/:main/:sub' component={InnerPage}/>
                    <Route exact path='/map/:id' component={AppBar}/>
                </Switch>
            </div>
        )
    }

}

export default withLocalize(Main);