import React, { Component } from 'react';
import {withLocalize, Translate} from "react-localize-redux";
import Greeting from './partials/Greeting';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import axios from "axios";
import {API_BASE} from "../config/env";
import {Link} from "react-router-dom";




class Search extends Component{

    constructor(props){
        super(props);
        this.state = {
            layoutName: "default",
            input: "",
            counter: 0,
            layout: {
                'default': [
                    '1 2 3 4 5 6 7 8 9 0',
                    'Q W E R T Y U I O P',
                    'A S D F G H J K L',
                    'Z X C V B N M {bksp}',
                    '{lang} {space} {enter}'
                ],
                'shift': [
                    '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
                    '{tab} Q W E R T Y U I O P { } |',
                    '{lock} A S D F G H J K L : " {enter}',
                    '{shift} Z X C V B N M < > ? {shift}',
                    '.com @ {space}'
                ]
            },
            layoutChanged: false,
            searchOn : false,
            loading: false,
            articles: []
    };
        this.changeLayout = this.changeLayout.bind(this)

    }




    onChange = input => {
        this.setState({
            input: input
        });
        if (input.length > 0) {
            this.search(input);
            this.setState({searchOn: true})
        }else{
            this.setState({searchOn: false})
        }
        console.log("Input changed", input);
    };

    onKeyPress = button => {
        console.log("Button pressed", button);
        if (button === "{shift}" || button === "{lock}") this.handleShift();
        if (button === "{lang}") this.changeLayout()
    };

    handleShift = () => {
        let layoutName = this.state.layoutName;

        this.setState({
            layoutName: layoutName === "shift" ? "default" : "shift"
        });
    };

    onChangeInput = event => {
        let input = event.target.value;
        this.setState(
            {
                input: input
            },
            () => {
                this.keyboardRef.keyboard.setInput(input);
            }
        );
    };


    changeLayout() {


        let layoutaz= {
            'default': [
                'q ü e r t y u i o p ö ğ',
                'a s d f g h j k l ı ə',
                'z x c v b n m ç ş {bksp}',
                '{lang} {space} {enter}'
            ],
            'shift': [
                '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
                '{tab} Q W E R T Y U I O P { } |',
                '{lock} A S D F G H J K L : " {enter}',
                '{shift} Z X C V B N M < > ? {shift}',
                '.com @ {space}'
            ]
        };
        let layout =
                {
                    'default': [
                        '1 2 3 4 5 6 7 8 9 0',
                        'Q W E R T Y U I O P',
                        'A S D F G H J K L',
                        'Z X C V B N M {bksp}',
                        '{lang} {space} {enter}'
                    ],
                    'shift': [
                        '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
                        '{tab} Q W E R T Y U I O P { } |',
                        '{lock} A S D F G H J K L : " {enter}',
                        '{shift} Z X C V B N M < > ? {shift}',
                        '.com @ {space}'
                    ]
                };


        if (!this.state.layoutChanged){
            this.setState({layout: layoutaz,layoutChanged : true});
        }else{
            this.setState({layout: layout, layoutChanged: false});
        }

    }

    search(searchTerm){
        let lang = localStorage.getItem('language');

        this.setState({ loading: true }, () => {
            axios.get(`${API_BASE}/search/keyword/${searchTerm}/lang/${lang}`)
                .then(result => result.data)
                .then(data => {
                    // console.log(data)
                    this.setState(prevState => ({
                        articles:  data,
                        loading: false
                    }))
                })
                .catch(error => console.log(error));
        });


    }

    render(){
        let title = <Translate id="search">Axtarış</Translate>;
        let sharedProps = {
            layoutName: this.state.layoutName,
            onChange: input => this.onChange(input),
            onKeyPress: button => this.onKeyPress(button),

            // This syncs the input of all keyboards
            syncInstanceInputs: true
        };

        console.log(this.state.articles)



        return(
            <div>
                {
                    !this.state.searchOn ? (
                        <Greeting title={title}/>
                    )
                        : (
                            <div className="search__result">
                                { this.state.loading ? (
                                    <div className="search__spinner"></div>
                                ) : (
                                    <div className="search__result-w">
                                        {this.state.articles.map((article,index)=>{
                                           return(

                                               <div className="menu__item-w--inner search__result-item" key={index}>
                                                   <Link to={{ pathname: `/main/organization/${article.id}`, state: { keyword: article}}} className='menu__item menu__item--inner'>
                                                       <span className="menu__item--icon menu__item--inner-icon search__result-icon"></span>
                                                       <span className="menu__txt menu__txt--inner">{article.name}</span>
                                                   </Link>
                                               </div>
                                           )
                                        })}
                                    </div>

                                ) }
                            </div>
                        )
                }
                {
                    !this.state.loading && this.state.searchOn ? (
                        <div className="search__result-count">{this.state.articles.length} <Translate id="result">nəticə...</Translate></div>
                    ):null
                }
                <div className="search__form">
                    <form>
                        <label htmlFor='search' className={'search__label'}>
                            <Translate>
                                {({ translate }) => <input placeholder={translate('search')}
                                                           type="text"
                                                           id="search"
                                                           className="search__input"
                                                           value={this.state.input}
                                                           onChange={e => this.onChangeInput(e)}
                                />}
                            </Translate>


                        </label>
                    </form>

                    <div className="keyboard__container">
                        <Keyboard ref={r => (this.keyboardRef = r)} layout={this.state.layout} {...sharedProps} />
                    </div>
                </div>
            </div>
        )
    }
}

export default withLocalize(Search);