import React , { Component } from 'react';
import { NavLink } from "react-router-dom";
import { withLocalize } from "react-localize-redux";


class NavigationBar extends Component{


    render() {


        let linksMarkup = this.props.links.map((link,index) =>{
            // console.log(link);

            return(
                <div className="menu__item-w" key={index} >
                    <NavLink   to={{ pathname: link.keyword, state: { keyword: link.keyword} }} className='menu__item'>
                        <span className={"menu__item--icon menu__item--icon-"+link.keyword}></span>
                        <span className="menu__txt">{link.name}</span>
                    </NavLink>
                </div>
            )
        });

        return (
            <div className="menu menu__bar">
                <div className="menu__line"></div>
                {linksMarkup}
            </div>
        )
    }
}

export default withLocalize(NavigationBar);