import React, {Component} from 'react';
import PageCap from '../partials/PageCap';
import MapContainer from '../partials/MapContainer';
import {withLocalize, Translate} from "react-localize-redux";
import {Link} from "react-router-dom";


class AppBar extends Component {

    constructor(){
        super();
        this.state = {
            tMode: ''
        }
    }

    componentDidMount() {
        this.setState(prevState => ({
            tMode : this.props.location.state.type
        }))
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState(prevState => ({
            tMode : nextProps
        }))
    }

    changeTravelMode(tMode){
        this.setState(prevState => ({
            tMode
        }));

    }

    render() {
        // console.log(this.props.location.state);

        // console.log(this.props.location.state);
        return (
            <div>
                <MapContainer lng={this.props.location.state.lng} lat={this.props.location.state.lat} tMode={this.state.tMode !== '' ? this.state.tMode : this.props.location.state.type}/>

                <div className={'map__page--cap'}>
                    <PageCap title={this.props.location.state.name}/>
                </div>
                <div className="map__details--on-map">
                    <div className="inner__page-to-map ">
                        <div className={`inner__page-to-map__item inner__page-to-map-w
                            ${this.state.tMode === 'WALKING' && this.state.tMode !== '' ? 'active' :''}`}
                             onClick={() => this.changeTravelMode('WALKING')}
                        >
                            <div id="pedestrian" className={`pedestrian route ${this.state.tMode === 'WALKING' ? 'open' : ''}`}>
                                <div className="route__duration">
                                    17:45 pm - 18:10 pm
                                </div>
                                <div className="route__distance">3.5km</div>
                                <div id="pedestrian-w" className={"route__pedestrian"}></div>
                            </div>

                            <i className="inner__page--icon to-map icon-man"></i>
                            <Translate id="pedestrian">Piyada</Translate>
                        </div>

                        <div className={`inner__page-to-map__item inner__page-to-map-w
                        ${ this.state.tMode === 'TRANSIT' ? 'active' : ''}`}
                             onClick={() => this.changeTravelMode('TRANSIT')} >
                            <div id="bus" className={`pedestrian route ${this.state.tMode === 'TRANSIT' ? 'open' : ''}`}>
                                <div className="route__duration">
                                    17:45 pm - 18:10 pm
                                </div>
                                <div className="route__distance">3.5km</div>

                                <div id="bus-w" className={"route__pedestrian"}></div>


                                {/*<div className="route__info">*/}
                                    {/*<div className="route__info--txt--bus">*/}
                                        {/*TÖVSİYƏ OLUNAN  MAŞURUT*/}
                                    {/*</div>*/}
                                    {/*<div className="route__info--el border__bottom">*/}
                                        {/*<div className="route__info--icon icon__bus"></div>*/}
                                        {/*<div className="route__info--bus">30</div>*/}

                                        {/*<div className="route__info--bar">*/}
                                            {/*hər 7 dəqiqədən bir Xətai m/s-dan*/}
                                        {/*</div>*/}
                                    {/*</div>*/}

                                    {/*<div className="route__info--txt--bus">*/}
                                        {/*DAHA ÇOX  MAŞURUT*/}
                                    {/*</div>*/}

                                    {/*<div className="route__info--el border__bottom">*/}
                                        {/*<div className="route__info--icon icon__man"></div>*/}
                                        {/*<div className="route__info--icon icon__arrow"></div>*/}
                                        {/*<div className="route__info--icon icon__bus"></div>*/}
                                        {/*<div className="route__info--bus">*/}
                                            {/*2*/}
                                            {/*<span className="center__line"></span>*/}
                                            {/*30*/}
                                        {/*</div>*/}
                                        {/*<div className="route__info--bar">*/}
                                            {/*hər 7 dəqiqədən bir Xətai m/s-dan*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                {/*</div>*/}

                            </div>
                            <i className="inner__page--icon to-map icon-bus"></i>
                            <Translate id="bus">Avtobus</Translate>
                        </div>


                        {/*<div className={`inner__page-to-map__item inner__page-to-map-w ${this.props.location.state.type === 'DRIVING' ? 'active' : ''}`}>*/}

                            {/*<div id="pedestrian" className="pedestrian route">*/}
                                {/*<div className="route__duration">*/}
                                    {/*17:45 pm - 18:10 pm*/}
                                {/*</div>*/}
                                {/*<div className="route__distance">3.5km</div>*/}
                            {/*</div>*/}

                            {/*<i className="inner__page--icon to-map icon-car"></i>*/}
                            {/*<Translate id="car">Maşın</Translate>*/}
                        {/*</div>*/}



                    </div>
                </div>
                <Link to='/' className="logo__holder-w">
                    <div className="logo__holder blue"></div>
                </Link>
            </div>
        )
    }
}

export default withLocalize(AppBar);