import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import SecondNav from "../SecondNav";
import Greeting from "../partials/Greeting";
import Logo from "../partials/Logo";
import axios from "axios";
import {API_BASE} from "../../config/env";


class  Entertainment extends Component{

    constructor(props) {
        super(props);
        this.state = {
            main: [],
            articles: [],
            subMenus:[]
        }

    }

    static propTypes = {
        entertainments: PropTypes.array
    };


    componentWillMount() {
        axios.get(`${API_BASE}/getSpecificMenu/menu/${this.props.location.state.keyword}/lang/${localStorage.getItem('language')}`)
            .then(result => result.data)
            .then(data => {
                // console.log(data)
                this.setState(prevState => ({
                    main:  data
                }))
            })
            .catch(error => console.log(error));


        let lang = localStorage.getItem('language');
        axios.get(`${API_BASE}/Submenu/menu/${this.props.location.state.keyword}/lang/${lang}`)
            .then(result => result.data)
            .then(data => {
                this.setState(prevState => ({
                    subMenus:  data
                }))
            })
            .catch(error => console.log(error));

    }


    render(){


        // let orgMenu = [
        //     { label : 'Muzey', name: 'muzey' , link: '/muzey'},
        //     { label : 'Teatr', name: 'teatr', link: '/teatr'},
        //     { label : 'Kino', name: 'kino' , link: '/kino'},
        //     { label : 'Alış-veriş mərkəzi', name: 'mall' , link: '/mall'}
        // ];
        // let mainMenu = {label:'Entertainment', name: 'entertainment', link: '/entertainemtn'} ;
        //
        // let title = 'Əyləncə';

        return(
            <div>
                <Greeting title={this.state.main.name}/>
                <SecondNav links={this.state.subMenus} main={this.state.main}/>
                <Logo/>
            </div>
        )
    }

}

const mapStateToProps = ({entertainments}) =>{
    return {
        entertainments
    }
};

export default connect(mapStateToProps)(Entertainment);