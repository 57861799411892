import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import SecondNav from "../SecondNav";
import Greeting from "../partials/Greeting";
import Logo from "../partials/Logo";
import axios from 'axios';
import {API_BASE} from "../../config/env";

class  Organizations extends Component{

    constructor(props) {
        super(props);
        this.state = {
            main: [],
            articles: []
        }

    }

    static propTypes = {
      organizations: PropTypes.array.isRequired
    };


    componentWillMount() {
        axios.get(`${API_BASE}/getSpecificMenu/menu/${this.props.location.state.keyword}/lang/${localStorage.getItem('language')}`)
            .then(result => result.data)
            .then(data => {
                // console.log(data)
                this.setState(prevState => ({
                    main:  data
                }))
            })
            .catch(error => console.log(error));


        let lang = localStorage.getItem('language');
        axios.get(`${API_BASE}/articles/menu/${this.props.location.state.keyword}/lang/${lang}`)
            .then(result => result.data)
            .then(data => {
                this.setState(prevState => ({
                    articles:  data
                }))
            })
            .catch(error => console.log(error));

    }



    render(){

        // console.log(this.props.location.state.keyword);
        // console.log(this.state.main)

        // let orgMenu = [
        //     { label : 'Milli Məclis', name: 'parliament' , link: '/parliament'},
        //     { label : 'Azərbaycan Respublikasının Prezidenti yanında Vətəndaşlara Xidmət və' +
        //             'Sosial İnnovasiyalar üzrə Dövlət Agentliyi', name: 'social', link: '/social'},
        //     { label : 'Azərbaycan Respublikası Nazirlər Kabineti' +
        //             'yanında "İçərişəhər" DTMQ İdarəsi', name: 'cabinet' , link: '/cabinet'},
        //     { label : 'Azərbaycan Respublikasının Prezidenti' +
        //             'yanında Vətəndaşlara Xidmət və ' +
        //             'Sosial İnnovasiyalar üzrə Dövlət Agentliyi', name: 'innovation' , link: '/innovation'},
        //     { label : 'Azərbaycan Respublikasının Prezidenti' +
        //             'yanında Vətəndaşlara Xidmət və ' +
        //             'Sosial İnnovasiyalar üzrə Dövlət Agentliyi1', name: 'innovation1' , link: '/innovation1'},
        //     { label : 'Azərbaycan Respublikasının Prezidenti2' +
        //             'yanında Vətəndaşlara Xidmət və ' +
        //             'Sosial İnnovasiyalar üzrə Dövlət Agentliyi', name: 'innovation2' , link: '/innovation2'},
        // ];

        // let mainMenu = {label:'Organization', name: 'organization', link: '/organization'} ;
        // let title = 'Dövlət Qurumları';


        return(
            <div className={'over__hide'}>
                <Greeting title={this.state.main.name}/>
                <SecondNav links={this.state.articles} main={this.state.main}/>
                <Logo/>
            </div>
        )
    }

}

const mapStateToProps = ({organizations}) =>{
  return {
      organizations
  }
};

export default connect(mapStateToProps)(Organizations);