import React   from 'react';

const Greeting = (props) =>{
    return(
        <div className="greeting__holder">
            <div className="greeting__txt">
                {props.title}
            </div>
            <div className="greeting__line"></div>
        </div>
    )
};


export default Greeting;


