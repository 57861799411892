import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import {API_BASE} from "../../config/env";
import {withLocalize, Translate} from "react-localize-redux";


class InnerPageDetails extends Component{


    constructor() {
        super();

        this.state = {
            showMenu: false,
            addClass: false,
            article: []
        };

        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }



    showMenu(event) {
        event.preventDefault();

        this.setState({ showMenu: true, addClass: true }, () => {
            document.addEventListener('click', this.closeMenu);
        });
    }

    closeMenu(event) {

        if (!this.dropdownMenu.contains(event.target)) {

            this.setState({ showMenu: false, addClass: false }, () => {
                document.removeEventListener('click', this.closeMenu);
            });

        }
    }


    componentWillMount() {
        let lang = localStorage.getItem('language');
        axios.get(`${API_BASE}/article/id/${this.props.data.articles_id}/lang/${lang}`)
            .then(result => result.data)
            .then(data => {
                // console.log(data)
                this.setState(prevState => ({
                    article:  data
                }))
            })
            .catch(error => console.log(error));

    }


    render() {
        let boxClass  = ['dropdown'];
        if(this.state.addClass) {
            boxClass.push('open');
        }

        return (
            <div className="inner__page-details">
                <ul className="inner__page-details--list">
                    <li>
                        <i className="inner__page--icon icon-location"></i>
                        {this.props.data.name3}
                    </li>
                    <li className={boxClass.join(' ')} onClick={this.showMenu}>
                        <i className="inner__page--icon icon-clock"></i>
                        {
                            !this.state.showMenu
                                ?
                                (
                                    <span ref={(element) => {
                                        this.dropdownMenu = element;
                                    }} className={'dropdown__inner'}>
                                        <b><Translate id="open">Açıq</Translate></b> <Translate id="close">Bağlı</Translate>
                                    </span>
                                )
                                :null
                        }
                        {
                            this.state.showMenu
                                ? (
                                    <table ref={(element) => {
                                        this.dropdownMenu = element;
                                    }} className="dropdown__list dropdown__list--inner dropdown__list--table">

                                        <tbody>
                                        <tr>
                                            <td><Translate id="monday">Bazar ertəsi</Translate></td>
                                            <td>{this.state.article.monday === 0 ? <Translate id="closed">Bağlıdır</Translate> :  this.state.article.work_hours_monday}</td>
                                        </tr>
                                        <tr>
                                            <td><Translate id="tuesday">Çərşənbə axşamı</Translate></td>
                                            <td>{this.state.article.tuesday === 0 ? <Translate id="closed">Bağlıdır</Translate> :  this.state.article.work_hours_tuesday}</td>
                                        </tr>
                                        <tr>
                                            <td><Translate id="wednesday">Çərşənbə</Translate></td>
                                            <td>{this.state.article.wednesday === 0 ? <Translate id="closed">Bağlıdır</Translate> :  this.state.article.work_hours_wednesday}</td>
                                        </tr>
                                        <tr>
                                            <td><Translate id="thursday">Cümə axşamı</Translate></td>
                                            <td>{this.state.article.thursday === 0 ? <Translate id="closed">Bağlıdır</Translate> :  this.state.article.work_hours_thursday}</td>
                                        </tr>
                                        <tr>
                                            <td><Translate id="friday">Cümə</Translate></td>
                                            <td>{this.state.article.friday === 0 ? <Translate id="closed">Bağlıdır</Translate> :  this.state.article.work_hours_friday}</td>
                                        </tr>
                                        <tr>
                                            <td><Translate id="saturday">Şənbə</Translate></td>
                                            <td>{this.state.article.saturday === 0 ? <Translate id="closed">Bağlıdır</Translate> :  this.state.article.work_hours_saturday}</td>
                                        </tr>
                                        <tr>
                                            <td><Translate id="sunday">Bazar</Translate></td>
                                            <td>{this.state.article.sunday === 0 ? <Translate id="closed">Bağlıdır</Translate> :  this.state.article.work_hours_sunday}</td>
                                        </tr>
                                        </tbody>

                                    </table>
                                )
                                : (
                                    null
                                )
                        }
                    </li>
                    <li>
                        <i className="inner__page--icon icon-globe"></i>
                        {this.state.article.website}
                    </li>
                    <li>
                        <i className="inner__page--icon icon-phone"></i>
                        {this.state.article.phone}
                    </li>
                </ul>

                <div className="inner__page-to-map">
                    <Link to={{ pathname: `/map/${1}`,state:{ type: 'WALKING',lng : this.state.article.longitude, lat : this.state.article.latitude,
                            name: this.props.data.abbv !== null && this.props.data.abbv !== '' ? this.props.data.abbv : this.props.data.name }
                    }}
                          className='inner__page-to-map-w'>
                        <div className="inner__page-to-map__item">
                                <i className="inner__page--icon to-map icon-man"></i>
                            <Translate id="pedestrian">Piyada</Translate>

                        </div>
                    </Link>

                    <Link  to={{ pathname: `/map/${1}`,state:{ type: 'TRANSIT', lng : this.state.article.longitude, lat : this.state.article.latitude, name: this.props.data.abbv !== null && this.props.data.abbv !== '' ? this.props.data.abbv : this.props.data.name
                    } }}
                           className='inner__page-to-map-w'>
                        <div className="inner__page-to-map__item">
                            <i className="inner__page--icon to-map icon-bus"></i>
                            <Translate id="bus">Avtobus</Translate>
                        </div>
                    </Link>


                </div>
                <Link to='/' className="logo__holder-w">
                    <div className="logo__holder blue"></div>
                </Link>
            </div>
        )
    }
};

export default withLocalize(InnerPageDetails);