import React , { Component } from 'react';
import {withLocalize, Translate} from "react-localize-redux";
import NavigationBar from './NavigationBar'
import Greeting from './partials/Greeting';
import Logo from './partials/Logo';
import { API_BASE } from "../config/env";
import axios from "axios";



class Home extends Component{

    constructor(){
        super();
        this.state = {
            menuLinks : [],
            activeLang : localStorage.getItem('language') !== null ? localStorage.getItem('language') : 'az'
        }
    }


    componentWillMount() {
        axios.get(`${API_BASE}/menu/lang/${this.state.activeLang}`)
            .then(result => result.data)
            .then(data => {
                this.setState(prevState => ({
                    menuLinks:  data
                }))
            })
            .catch(error => console.log(error));
    }

    setLanguage(lang) {
        this.props.setActiveLanguage(lang);
        localStorage.setItem('language', lang);
        this.setState({
            activeLang: localStorage.getItem('language')
        });

        axios.get(`${API_BASE}/menu/lang/${lang}`)
            .then(result => result.data)
            .then(data => {
                this.setState(prevState => ({
                    menuLinks:  data
                }))
            })
            .catch(error => console.log(error));
    }

    render(){

        // let menuLinks = [
        //     { label : 'Organizations', name: 'organization' , link: '/organization'},
        //     { label : 'Entertainment', name: 'entertainment', link: '/entertainment'},
        //     { label : 'Restaurants', name: 'restaurant' , link: '/restaurants'},
        //     { label : 'Qaynar Xett', name: 'alo' , link: '/alo141'},
        //     { label : 'Search', name: 'search' , link: '/search'}
        // ];

        let title = <Translate id="greeting">Xoş Gəlmisiniz!</Translate>;

            return (
                <div className={'over__hide'}>
                    <Greeting title={title}/>
                    <NavigationBar links={this.state.menuLinks}/>

                    <div className="lang__bar">
                        {this.props.languages.map(lang => (

                            <div className={`lang__item ${this.props.activeLanguage.code === lang.code ? 'active' : ''}`} key={lang.code}>
                                <div className="lang__item-w" onClick={() =>this.setLanguage(lang.code) } >{lang.code}</div>
                            </div>
                        ))}
                    </div>

                    <Logo/>
                </div>
            )
    }

}

export default withLocalize(Home);