import React from 'react';


const InnerPageInfo = (props) => {

    return(
        <div className="inner__page--info-w">
            <div className="inner__page--info-txt">{props.title}</div>
            {/*<div className="inner__page--info-txt">Test</div>*/}
            <div className="inner__page--info-map-icon"></div>
        </div>
    )

};

export default InnerPageInfo;