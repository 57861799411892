import React   from 'react';

const PageCap = (props) =>{
    return(
         <div className="inner__page-w">
             <div className="inner__page-txt">{props.title}</div>
             {/*<div className="inner__page-txt">Test</div>*/}
            <div className="inner__page-line"></div>
        </div>
    )
};


export default PageCap;


