import React , { Component } from 'react';
import Slider from '../Slider';
import PageCap from '../partials/PageCap';
import InnerPageInfo from "../partials/InnerPageInfo";
import InnerPageDetails from "../partials/InnerPageDetails";



class InnerPage extends Component{


    render(){
        return(
            <div className='inner__page over__hide-x'>
                <PageCap title={this.props.location.state.keyword.abbv !== null && this.props.location.state.keyword.abbv !== '' ? this.props.location.state.keyword.abbv : this.props.location.state.keyword.name }/>
                <Slider/>
                <InnerPageInfo title={this.props.location.state.keyword.abbv !== null && this.props.location.state.keyword.abbv !== '' ? this.props.location.state.keyword.abbv : this.props.location.state.keyword.name}/>
                <InnerPageDetails data={this.props.location.state.keyword}/>
            </div>
        )
    }

}
export  default InnerPage;