import React , { Component } from 'react';
import { NavLink, Link } from "react-router-dom";
import ScrollAnimation from 'react-animate-on-scroll';


export default  class SecondNav extends Component{

    render() {

        let subMenuMarkup = this.props.links.map((link,index) =>{
            if (this.props.main.keyword !== 'entertainment') {

                return (

                    <ScrollAnimation animateIn='fadeIn' animateOut="fadeOut" className={"menu__animated" + index}
                                     scrollableParentSelector='#scrolly-div-animatePreScroll' duration={1}
                                     key={index}>
                        <div className="menu__item-w--inner">
                            <Link to={{ pathname: `/main/${this.props.main.keyword }/${link.id}`, state: { keyword: link}}} className='menu__item menu__item--inner'>
                                <span className="menu__item--icon menu__item--inner-icon"></span>
                                <span className="menu__txt menu__txt--inner">{link.name}</span>
                            </Link>
                        </div>
                    </ScrollAnimation>
                );
            }else{
                return (

                    <ScrollAnimation animateIn='fadeIn' animateOut="fadeOut" className={"menu__animated" + index}
                                     scrollableParentSelector='#scrolly-div-animatePreScroll' duration={1}
                                     key={index}>
                        <div className="menu__item-w--inner">
                            <NavLink to={{ pathname: `/entertainment/${link.keyword}`, state: {keyword: link, main: this.props.main} }} className='menu__item menu__item--inner'>
                                <span className="menu__item--icon menu__item--inner-icon"></span>
                                <span className="menu__txt menu__txt--inner">{link.name}</span>
                            </NavLink>
                        </div>
                    </ScrollAnimation>
                );
            }
        });

        return (
            <div className="menu menu--inner menu__bar" id={"scrolly-div-animatePreScroll"}>
                <div className="menu__line"></div>
                <div className="menu__item-w active this__menu">
                    <NavLink to='/' className='menu__item'>
                        <span className={"menu__item--icon menu__item--icon-"+this.props.main.keyword}></span>
                        <span className="menu__txt">{this.props.main.name}</span>
                    </NavLink>
                </div>
                {subMenuMarkup}
            </div>
        )
    }
}