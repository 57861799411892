import React from 'react';
import ReactDOM from 'react-dom';
import "animate.css/animate.min.css";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from "redux-devtools-extension";
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";


import rootReducer from './reducers/rootReducer';
import GoogleMaps from 'google-maps';
import domReady from 'domready';


const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
);

// ReactDOM.render(
//     <BrowserRouter>
//         <Provider store={store}>
//             <App />
//         </Provider>
//     </BrowserRouter>,
//     document.getElementById('root'));


domReady(()=> {
    GoogleMaps.LIBRARIES = ['places'];
    GoogleMaps.KEY='AIzaSyCCnB8kTg7wTYAmx9RHpeha37CawcQGljU';

    GoogleMaps.load((google)=> {

        ReactDOM.render(
            <BrowserRouter>
                <Provider store={store}>
                    <App mapService={google}/>
                </Provider>
            </BrowserRouter>,
            document.getElementById('root'));
    });

});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
