import React from 'react';

import { LocalizeProvider } from "react-localize-redux";


//components
import Main from './components/Main'
import './App.css';


const App = props => (
    <LocalizeProvider>
        <Main/>
    </LocalizeProvider>
);

// class App extends Component {
//   render() {
//     return (
//         <LocalizeProvider>
//             <Main/>
//         </LocalizeProvider>
//     );
//   }
// }

export default App;
