import React, {Component} from 'react';
import $ from 'jquery';
import ReactDOM from 'react-dom';

let instructions = [];


class MapContainer extends Component {

    constructor() {
        super();
        this.state = {
            lists: [],
            tMode: ''
        };
    }

    componentDidMount() {
        this.initMap(ReactDOM.findDOMNode(this));
        this.mapMarker();
        this.removeMapDefs();
        this.setMapDirection();

    }

    componentWillReceiveProps(nextProps) {
        if (this.directionsDisplay !== undefined && nextProps.mapData !== undefined) {
            this.directionsDisplay.setDirections(nextProps.mapData);
        }
        // console.log(this.props.tMode);
        // console.log(this.state.tMode)
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        // console.log(nextProps);
        let google = window.google;
        let directionsDisplay = new google.maps.DirectionsRenderer();

        directionsDisplay.setMap(null)

        this.initMap(this.map);


        let directionsService = new google.maps.DirectionsService();
        let directionsDisplay1 = new google.maps.DirectionsRenderer({
            map: this.map,
            preserveViewport: true,
            suppressMarkers: true,
            polylineOptions: {
                strokeColor: '#0754d3'
            },
        });

        let pointA = new google.maps.LatLng(40.4216151,49.9146403);
        let pointB = new google.maps.LatLng(this.props.lat,this.props.lng);
        // let pointB = new google.maps.LatLng(40.4089885,49.889091);

        this.calculateAndDisplayRoute(directionsService,directionsDisplay1,pointA,pointB,nextProps.tMode)

        // this.setState({
        //     tMode :  nextProps.tMode
        // });
    }

    initMap(elm) {
        if (!!elm && !this.map) {
            let google = window.google;
            this.directionsDisplay = new google.maps.DirectionsRenderer({map: this.map,preserveViewport: true,suppressMarkers: true});
            this.map = new google.maps.Map(elm, {
                center: {lat: 40.4216151, lng: 49.9146403},
                zoom: 13,
                zoomControl: false,
                mapTypeControl: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                    position: google.maps.ControlPosition.RIGHT_CENTER
                },
                zoomControlOptions: {
                    style: google.maps.ZoomControlStyle.SMALL,
                    position: google.maps.ControlPosition.RIGHT_CENTER
                },
                styles:   [
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#f5f5f5"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#bdbdbd"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e5e5e5"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dadada"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#e5e5e5"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#eeeeee"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#c9c9c9"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    }
                ]
            });
            this.directionsDisplay.setMap(this.map);
        }
    }

    mapMarker(){
        let locations = [
            {title: '', lat: 40.4216151, lon: 49.9146403,icon:require('../../img/marker.png')},
            {title: '', lat: this.props.lat,lon: this.props.lng,icon:require('../../img/marker.png')},
            // {title: '', lat: 40.4089885,lon: 49.889091,icon:require('../../img/marker.png')},
        ];
        let google = window.google;
        let i;

        let markers = [];

        for (i = 0; i < locations.length; i++) {

            let marker = new google.maps.Marker({
                map: this.map,
                position: new google.maps.LatLng(locations[i].lat, locations[i].lon),
                animation: google.maps.Animation.DROP,
                icon: locations[i].icon,
                label: locations[i].label,
            });
            markers.push(marker);
        }
    }

    setMapDirection(){
        let google = window.google;

        let directionsDisplay = new google.maps.DirectionsRenderer();

        let directionsService = new google.maps.DirectionsService({
            map: this.map,
            preserveViewport: true,
            suppressMarkers: true,
            polylineOptions: {
                strokeColor: '#0754d3'
            },
        });

        // console.log(this.props.tMode);
        // console.log(this.state.tMode.length)
        let tMode = this.state.tMode.length !== 0 &&  this.state.tMode !== ' ' ? this.state.tMode : this.props.tMode;


        let pointA = new google.maps.LatLng(40.4216151,49.9146403);
        let pointB = new google.maps.LatLng(this.props.lat,this.props.lng);
        // let pointB = new google.maps.LatLng(40.4089885,49.889091);

        this.calculateAndDisplayRoute(directionsService,directionsDisplay,pointA,pointB,tMode)
    }

    calculateAndDisplayRoute(directionsService,directionsDisplay,pointA,pointB,tMode) {



        directionsService.route({
            origin: pointA,
            destination: pointB,
            avoidTolls: true,
            avoidHighways: true,
            language:'az_AZ',
            travelMode: tMode,
            // transitOptions: {
            //     modes: ['BUS']
            // }
        }, function (response,status) {
            // to get and show route details in card

            let google = window.google;
            let geocoder =  new google.maps.Geocoder();

            if (status === google.maps.DirectionsStatus.OK){
                directionsDisplay.setDirections(response);

                let mainEl,childrenEl;
                let maneuver = [];

                let route = response.routes[0];
                let legs = route.legs[0];

                // console.log(legs);

                // console.log(route)
                if (response.request.travelMode === 'WALKING')
                {
                    mainEl = $('#pedestrian');
                    mainEl.children('.route__distance').text(legs.distance.text);
                    mainEl.children('.route__duration').text(legs.duration.text);

                    legs.steps.map((step,i) => {

                        // console.log(step)
                        // maneuver.push(step.maneuver);

                        // setTimeout(function () {
                        //     geocoder.geocode({'latLng': step.start_location}, function(results, status) {
                        //         if (status === 'OK') {
                        //             instructions.push(results[0].formatted_address);
                        //
                        //             // appendElem(maneuver[i], results[0].formatted_address);
                        //         }else {
                        //             console.log('Can not find location ' + status);
                        //         }
                        //
                        //     });
                        //
                        // },2000);



                        // function appendElem(maneuver,instructions){
                            if (maneuver === '')
                            {
                                maneuver = 'arrow__up';
                            }

                            childrenEl = '<div class="route__info">' +
                                '<div class="route__info--el">' +
                                '<div class="route__info--icon '+step.maneuver+'"></div>' +
                                // '<div class="route__info--txt">'+instructions.substr(0,instructions.indexOf(','))+'</div>' +
                                '<div class="route__info--txt">'+step.instructions+'</div>' +
                                '</div>' +
                                '</div>';

                        // mainEl.append(childrenEl);
                        $('#pedestrian-w').append(childrenEl);
                        // }

                    });

                }else if(response.request.travelMode === 'TRANSIT')
                {
                    mainEl = $('#bus');
                    mainEl.children('.route__distance').text(legs.distance.text);
                    mainEl.children('.route__duration').text(legs.departure_time.text + ' - ' + legs.arrival_time.text);

                    let inst = [];
                    legs.steps.map((step,i) => {
                        console.log(step);

                        if (step.travel_mode === 'WALKING'){
                            inst.push('icon__man')
                        }else if (step.travel_mode === 'TRANSIT'){
                            if (step.instructions.match('Subway')){
                                inst.push('icon__bus')
                            }else{
                                inst.push('icon__bus')
                            }
                        }

                        // if (i % 2 !== 0)
                        // {
                        //     // console.log(legs.steps[i])
                        // }
                        // maneuver.push(step.maneuver);

                        // geocoder.geocode({'latLng': step.start_location}, function(results, status) {
                        //     // console.log(results)
                        //
                        //     if (status === 'OK') {
                        //         instructions.push(results[0].formatted_address);
                        //
                        //         appendElem(maneuver[i], results[0].formatted_address);
                        //     }else {
                        //         console.log('Can not find location' + status);
                        //     }
                        //
                        // });


                        // function appendElem(maneuver,instructions){
                        //     if (maneuver === '')
                        //     {
                        //         maneuver = 'arrow__up';
                        //     }
                        //
                            childrenEl = '<div class="route__info">' +
                                '<div class="route__info--el">' +
                                '<div class="route__info--icon '+inst[i]+'"></div>' +
                                '<div class="route__info--txt">'+step.instructions+'</div>' +
                                '</div>' +
                                '</div>';
                            $('#bus-w').append(childrenEl);
                        // }

                    });


                }else if(response.request.travelMode === 'DRIVING'){

                    mainEl = $('#driving');
                    mainEl.children('.route__distance').text(legs.distance.text);
                    mainEl.children('.route__duration').text(legs.duration.text);

                    legs.steps.map((step,i) => {
                        // console.log(step);


                        maneuver.push(step.maneuver);
                        setTimeout(function () {

                            geocoder.geocode({'latLng': step.start_location}, function(results, status) {
                                // console.log(results)

                                if (status === 'OK') {
                                    instructions.push(results[0].formatted_address);

                                    appendElem(maneuver[i], results[0].formatted_address);
                                }else {
                                    console.log('Can not find location ' + status);
                                }

                            });

                        },100);



                        function appendElem(maneuver,instructions){

                            // axios.get(`http://terminal.localhost/ajax/menu`)
                            //     .then(res => {
                            //        console.log(res)
                            //     });

                            if (maneuver === '')
                            {
                                maneuver = 'arrow__up';
                            }

                            childrenEl = '<div class="route__info">' +
                                '<div class="route__info--el">' +
                                '<div class="route__info--icon '+maneuver+'"></div>' +
                                '<div class="route__info--txt">'+instructions+'</div>' +
                                '</div>' +
                                '</div>';
                            mainEl.append(childrenEl);
                        }

                    })
                }


            } else
            {
                console.log('Directions request failed due to' + status)
            }
        });
    }

    removeMapDefs(){
        window.google.maps.event.addDomListener(window, 'load', function () {
            $('.gm-style-iw').prev('div').remove();
        });
    }




    render() {
        return (
            <div id="map"></div>
        )
    }


}


export default MapContainer;