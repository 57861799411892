import React from 'react';
import {Link} from "react-router-dom";


const Logo = (props) =>{
    return(
        <Link to='/' className="logo__holder-w">
            <div className="logo__holder"></div>
        </Link>
    )
}

export default Logo;