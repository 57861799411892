import React, { Component } from "react";
import Slider from "react-slick";


export default class slider extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
        };
        let images = [
            { 'image' : require('../img/2.jpg') },
            { 'image' : require('../img/3.jpg') },
            { 'image' : require('../img/4.jpg') },
            { 'image' : require('../img/6.jpg') },
        ];


        let imageToArr =  images.map((image,index) => {
            return(
                <div className='slider__img' key={index}>
                    <div style={{ backgroundImage: 'url(' + image.image+ ')'}}></div>
                </div>
            )
        });

        return (
            <div>
                <Slider {...settings}>
                    {imageToArr}
                </Slider>
            </div>
        );
    }
}